<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      active: isActive,
      disabled: item.disabled
    }"
  >
    <b-link v-bind="linkProps" class="d-flex align-items-center" @click="reloadPage()">
      <feather-icon :icon="item.icon || 'CircleIcon'" />
      <span class="menu-title text-truncate">{{ t(item.title) }}</span>
      <b-badge v-if="item.tag" pill :variant="item.tagVariant || 'primary'" class="mr-1 ml-auto">
        {{ $store.state.messageries.count_message_unread }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { BLink, BBadge } from 'bootstrap-vue'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'

export default {
  components: {
    BLink,
    BBadge
  },
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data(){
    return {}
  },
  mounted() {
    let host = window.location.host;

    if (host.includes("erp.bubble-in.com") || host.includes("test.bubble-in.com") || host.includes("demo.bubble-in.com")) {
      let recaptchaScriptzdassets = document.createElement('script')
      recaptchaScriptzdassets.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=1b1c5fe8-19aa-487f-bf66-4ecc83d2124c`)
      recaptchaScriptzdassets.setAttribute('id', `ze-snippet`)
      document.head.appendChild(recaptchaScriptzdassets)
    }
  },
  methods:{
    reloadPage(){
      if (this.item.route === 'tiers-list' && document.getElementById('revenir-liste-client')){
        document.getElementById('revenir-liste-client').click()
      }
      if (this.item.route === 'projets-list' && document.getElementById('revenir-liste-projets')){
        document.getElementById('revenir-liste-projets').click()
      }
      // revenir_liste_client_entreprise

    }
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(props.item)
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t
    }
  }
}
</script>
