export default [
    {
        title: 'Ma marketplace',
        icon: 'ShoppingBagIcon',
        tagVariant: 'light-danger',
        typeUser: 'all',
        children: [
          {
            title: 'Mon catalogue',
            route: 'catalogues-list',
            typeUser: 'courtier',
          },
          {
            title: 'Mes devis',
            route: 'projets-list',
            typeUser: 'courtier',
          },
          {
            title: 'Ma tarification',
            route: 'quickwins-tarification',
            typeUser: 'all',
          }
        ],
      }
    ]