
export default [
{
    title: 'Mon portefeuille',
    icon: 'BriefcaseIcon',
    tagVariant: 'light-danger',
    typeUser: 'courtier',
    children: [
      {
        title: 'Mes clients',
        route: 'tiers-list',
        typeUser: 'courtier',
      },
      {
        title: 'Mes contrats',
        route: 'contrats-list',
      },
      {
        title: 'Mes sinistres',
        route: 'sinistres-list',
      },
      {
        title: 'Mes réclamations',
        route: 'reclamations-list',
      }
      
    ],
  }
]
