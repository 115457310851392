export default [{
    title: 'Portefeuille',
    icon: 'ShoppingCartIcon',
    tagVariant: 'light-danger',
    typeUser: 'assureur',
    children: [{
            title: 'Devis',
            route: '',
        },
        {
            title: 'Contracts',
            route: '',
        },
        {
            title: 'Reclamations',
            route: '',
        },
        {
            title: 'Sinistres',
            route: '',
        },
        {
            title: 'Bordereaux',
            route: '',
        }
    ]
}]