export default [{
  title: 'Mon cabinet',
  icon: 'ShieldIcon',
  tagVariant: 'light-danger',
  typeUser: 'courtier',
  children: [
    {
      title: 'Ma structure',
      route: 'gestion-cabinet',
    },
    {
      title: 'Mes interlocuteurs',
      route: 'gestion-interlocuteurs',
    }
  ],
}]
