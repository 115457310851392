<template>
  <ul>
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in getItems"
      :key="item.header || item.title"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    getItems: function () {
      let itemsUpdated = this.items.filter((item) => this.checkVisibiltyMenu(item.typeUser) && this.hasPermission(item.route))
      itemsUpdated.map((item, index) => {
        if (item.hasOwnProperty('children') && item.children.length > 0) {
          let listDestroy = []
          item.children.forEach((child, indexChild) => {
            if (!this.hasPermission(child.route))
              listDestroy.push(child.route)
          })

          item.children = item.children.filter(item => {
            return !listDestroy.includes(item.route)
          })
          listDestroy = []
        }
      })

      return itemsUpdated
    }
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    checkVisibiltyMenu(typeUser) {
      const typeUserLocal = this.userData.type_utilisateur
      switch (typeUserLocal) {
        case 'admin':
          if (typeUser === 'courtier') return true
          if (typeUser === 'admin') return true
          if (typeUser === 'assureur') return false
          if (typeUser === 'all') return true
          break
        case 'courtier':
          if (typeUser === 'courtier') return true
          if (typeUser === 'admin') return false
          if (typeUser === 'assureur') return false
          if (typeUser === 'all') return true
          break
        case 'assureur':
          if (typeUser === 'courtier') return false
          if (typeUser === 'admin') return false
          if (typeUser === 'assureur') return true
          if (typeUser === 'all') return true
          break
        default:
          // code block
      }
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>
