export default [{
    title: 'Organisation',
    icon: 'DatabaseIcon',
    tagVariant: 'light-danger',
    typeUser: 'assureur',
    children: [{
            title: 'Utilisateurs',
            route: '',
        },
        {
            title: 'Reseau',
            route: '',
        },
        {
            title: 'Accrediations',
            route: '',
        }
    ]
}]