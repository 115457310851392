export default [
  {
    title: 'Coffre fort conformité',
    icon: 'AwardIcon',
    route: 'conformite-list',
    typeUser: 'courtier',
    children: [
      {
        title: 'Liste de conformité',
        route: 'conformite-list',
      },
      {
        title: 'Gel des avoirs',
        route: 'gda-list',
      },
      {
        title: 'Guides de conformité',
        route: 'conformite-guide',
      },
    ],
  }
]
