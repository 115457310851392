<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.filter(notification => notification.isRead == 0).length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
          {{notifications.filter(notification => notification.isRead == 0).length}} Nouveau
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
      v-if="dataLoaded"
    >
      <!-- Account Notification -->
      <b-link
        v-for="notification in notifications"
        :key="notification.id"
      >
        <b-media :class="notification.isRead ? 'isread' : 'isnotread'" @click="setRead(notification.notificationuserid,notification.isRead)">
          <template #aside>
            <b-avatar
              size="32"
              :src="notification.type.charAt(0)"
              :text="notification.type.charAt(0)"
              :variant="notificationColors[notification.type]"
            />
          </template>
          <!-- <p class="media-heading">
            <span class="title-notification">
              {{ notification.objet }} - {{ checkAllDay(notification.date) }}
            </span>
            
          </p> -->
          
          <p class="media-heading" >
            <span class="title-notification">
              {{ notification.objet }}
              <span v-if="notification.type!='Weedo_market' && notification.type!='GDA'">  - {{ checkAllDay(notification.date) }}</span>
              <div v-else style="margin-top:3px;">
                (<a style="cursor:pointer;text-decoration: underline; " @click="getclient(notification.notificationable_id)">voir les notes pour le détail</a>) - {{ checkAllDay(notification.date) }}
              </div>
            </span>
          
          </p>

          <small><span v-if="notification.duration">Durée : </span>{{notification.duration}}</small>
          <small class="time-notification">{{ notification.created_at }}</small>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>
    <!-- Cart Footer -->
    <!-- <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li> -->
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import environnement from '@/shared/api/globalParametres'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data(){
    return{
      currentUser: JSON.parse(localStorage.getItem('userData')),
      dataLoaded : false,
      notifications: [
      // {
      //   title: 'Congratulation Sam 🎉',
      //   avatar: require('@/assets/images/avatars/6-small.png'),
      //   subtitle: 'Won the monthly best seller badge',
      //   type: 'light-success',
      // },
      // {
      //   title: 'New message received',
      //   avatar: require('@/assets/images/avatars/9-small.png'),
      //   subtitle: 'You have 10 unread messages',
      //   type: 'light-info',
      // },
      // {
      //   title: 'Revised Order 👋',
      //   avatar: 'MD',
      //   subtitle: 'MD Inc. order updated',
      //   type: 'light-danger',
      // },
    ],
    notificationColors: {'PROJET' : 'light-success','TACHE' : 'light-primary','EVENT' : 'light-info','CONTRAT' : 'light-danger','Weedo_market' : 'light-success'},
    perfectScrollbarSettings: {
      maxScrollbarLength: 100,
      wheelPropagation: false,
    }
    }
  },
  methods: {
    isTaskOrEventOfDay(date, task_or_event_date) {
      const dateMonth = date.getMonth() + 1;
      const dateDay = date.getDate();
      const birthMonth = task_or_event_date.getMonth() + 1;
      const birthDay = task_or_event_date.getDate();

      return dateMonth === birthMonth && dateDay === birthDay;
    },
    downloadDocument(base64, name) {
      if (base64) {
        const a = document.createElement('a')
        a.href = base64
        a.download = name
        a.click()
        this.messageToast('Le document a été téléchargé avec succès.', 'Succès', 'success')
      } else {
        this.messageToast('Aucun document trouvé à télécharger, veuillez essayer de le télécharger après.', 'Erreur', 'warning')
      }
    },
    getclient(prospectId){
      if(this.can('Show detail client')) {
        if(this.$route.name=='tiers-list'){
          this.$router.push({ path: "/"})
                    .then(() => this.$router.replace({ 
                      name: "tiers-list", 
                      params: { 
                        'id': prospectId,
                        'type':'PERSONNE_PHYSIQUE',
                        'interfaceChosen': 'Professionnel'
                      }}
                    ))
                    .catch(() => {})
        }
        else{
          this.$router.push({
            name: 'tiers-list',
            params: {
              'id': prospectId,
              'type':'PERSONNE_PHYSIQUE',
              'interfaceChosen': 'Professionnel'
            }
          })
        }
      }
    },
    async fetchNotifications() {
      this.notifications=[]
      await this.$http
          .get('/notification/user')
          .then(response => {
            this.notifications=[]
            response.data.data.forEach(element => {
              let notif = element.objet.split(':');
              let date = element.date.split(' ');
              if((notif[0] === 'Tâche' || notif[0] === 'Rdv') && this.isTaskOrEventOfDay(new Date(),new Date(date[0]))){
                
                notif[0] = notif[0] + ' du jour';
                
                element.objet = notif.join(': ')
              }
              // Tache Événement
              this.notifications.push(element)
            });
            this.dataLoaded = true
          })
          .catch((err) => {
            console.log(err)
          })
    },
    setRead(notificationuserid,read)
    {
      if(!read){
        this.$http
          .post('/notification/setread',{id:notificationuserid})
          .then(response => {
            this.notifications.forEach(notification => {
              if(notification.notificationuserid == notificationuserid)
              {
                console.log(notification.notificationuserid, response.data.data.id,'yes');
                notification.isRead = 1;
              }
            });
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    checkAllDay(date)
    {
      let splited_date = date.split(' ');
      date = new Date(date);
      if(splited_date[1] == "00:00:00")
      {
        date = new Date(splited_date[0])
        let dateStr =
        ("00" + date.getDate()).slice(-2) + "/" +
        ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
        date.getFullYear();
        return dateStr;
      }
      let dateStr =
      ("00" + date.getDate()).slice(-2) + "/" +
      ("00" + (date.getMonth() + 1)).slice(-2) + "/" +
      date.getFullYear() + " " +
      ("00" + date.getHours()).slice(-2) + ":" +
      ("00" + date.getMinutes()).slice(-2);
      return dateStr;
    }
  },
  created(){
    window.Pusher = Pusher
      // This assumes you have already saved your bearer token in your browsers local storage

      //prod config

      // window.Echo = new Echo({
      //   broadcaster: 'pusher',
      //   key: 'bubbleinkey',
      //   cluster: 'mt1',
      //   wsHost: '127.0.0.1', 
      //   authEndpoint: 'http://127.0.0.1/api/broadcasting/auth',
      //   wsPort: 6001,
      //   wssPort: 6001,
      //   disableStats: true,
      //   forceTLS: false,
      //   encrypted: true,
      //   enabledTransports: ['ws', 'wss'],
      //   auth: {
      //     headers: {
      //         Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
      //         Accept: 'application/json',
      //         'X-Tenant': 'my-tenant',
      //     },
      //   },
      // })

      //end prod config
      window.Echo = new Echo({
        broadcaster: 'pusher',
        key: 'bubbleinkeydemo',
        cluster: 'mt1',
        wsHost: environnement.wsHost,
        authEndpoint: 'https://'+environnement.wsHost+'/api/broadcasting/auth',
        wsPort: environnement.socketPort,
        wssPort: environnement.socketPort,
        disableStats: true,
        forceTLS: true,
        encrypted: true,
        auth: {
          headers: {
              Authorization: 'Bearer ' + localStorage.getItem('accessToken'),
              Accept: 'application/json',
              'X-Tenant': 'my-tenant',
          },
        },
      })

      

    window.Echo.private('notificationchannel.'+this.currentUser.id)
        .listen('NotificationEvent', (data) => {
          //this.messageToast(data.response.message, 'Produit', 'success', 'BellIcon')
          this.notifications = []
          this.fetchNotifications()
    })
    window.Echo.channel('test-websocket')
        .listen('TestWebsocket', (data) => {
          console.log('test-websocket')
    })
    window.Echo.channel('extractiondocument')
        .listen('ExtractionDocument', (data) => {
          this.$http
        .get(`/document/generate_base64_for_document/${data.id_document}`)
        .then(res => {
          if (res.data.success) {
              this.downloadDocument(res.data.data.base64, 'DEVIS_STATISTICS')
          } else {
            this.messageToast(res.data.message, 'Erreur', 'error')
          }
        })
        .catch(err => {
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
      })
    this.fetchNotifications()
    // this.$root.$emit('cool_event_name', [])

  }
}
</script>

<style>
.isnotread{
  background: #e9e2ef;
}
.isread{
  background: #fff;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-cart .notification-text, .header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .notification-text
{
  color: #646464;
}
.header-navbar .navbar-container ul.navbar-nav li.dropdown-notification .media-body .media-heading
{
  color: #161515;
}
.time-notification
{
  float:right;
  font-size:10px;
  font-weight:bold;
}
.title-notification
{
  font-size:12px;
  font-weight:bold;
}

</style>
