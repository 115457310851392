<template>
  <b-nav-item-dropdown
      class="dropdown-user"
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
  >

    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <!-- <p class="user-name font-weight-bolder mb-0">
          {{ userData.fullName || userData.username }}
        </p> -->
        <p class="user-name font-weight-bolder mb-0" v-if="userData.personne_physique">
          {{ userData.personne_physique.prenom + ' ' + userData.personne_physique.nom }}
        </p>
        <p class="user-name font-weight-bolder mb-0" v-else>
          {{ userData.name }}
        </p>
        <!-- <span class="user-status">{{ userData.role }}</span> -->
      </div>
      <b-avatar
          badge

          badge-variant="success"
          class="badge-minimal text-uppercase"
          size="40"
          variant="light-primary"
      >
        <span v-if="userData.personne_physique">{{ userData.personne_physique.prenom.charAt(0) + userData.personne_physique.nom.charAt(0) }}</span>
        <span v-else>{{ userData.name.charAt(0) }}</span>
        <!-- <feather-icon
          v-if="!userData.name"
          icon="UserIcon"
          size="22"
        /> -->
      </b-avatar>
    </template>

<!--    <b-dropdown-item-->
<!--        :to="{ name: 'pages-profile'}"-->
<!--        link-class="d-flex align-items-center"-->
<!--    >-->
<!--      <feather-icon-->
<!--          class="mr-50"-->
<!--          icon="UserIcon"-->
<!--          size="16"-->
<!--      />-->
<!--      <span>Profil</span>-->
<!--    </b-dropdown-item>-->
<!--    <b-dropdown-item-->
<!--        :to="{ name: 'messageries' }"-->
<!--        link-class="d-flex align-items-center"-->
<!--    >-->
<!--      <feather-icon-->
<!--          class="mr-50"-->
<!--          icon="MailIcon"-->
<!--          size="16"-->
<!--      />-->
<!--      <span>Messagerie</span>-->
<!--    </b-dropdown-item>-->
<!--    <b-dropdown-item-->
<!--        :to="{ name: 'apps-todo' }"-->
<!--        link-class="d-flex align-items-center"-->
<!--    >-->
<!--      <feather-icon-->
<!--          class="mr-50"-->
<!--          icon="CheckSquareIcon"-->
<!--          size="16"-->
<!--      />-->
<!--      <span>Tâches</span>-->
<!--    </b-dropdown-item>-->
    <!-- <b-dropdown-item
      :to="{ name: 'apps-chat' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="MessageSquareIcon"
        class="mr-50"
      />
      <span>Chat</span>
    </b-dropdown-item> -->

<!--    <b-dropdown-divider/>-->

<!--    <b-dropdown-item-->
<!--        :to="{ name: 'mon-organisation' }"-->
<!--        link-class="d-flex align-items-center"-->
<!--    >-->
<!--      <feather-icon-->
<!--          class="mr-50"-->
<!--          icon="SettingsIcon"-->
<!--          size="16"-->
<!--      />-->
<!--      <span>Paramètres</span>-->
<!--    </b-dropdown-item>-->
    <!-- <b-dropdown-item
      :to="{ name: 'pages-pricing' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="CreditCardIcon"
        class="mr-50"
      />
      <span>Pricing</span>
    </b-dropdown-item> -->
<!--    <b-dropdown-item-->
<!--        :to="{ name: 'pages-faq' }"-->
<!--        link-class="d-flex align-items-center"-->
<!--    >-->
<!--      <feather-icon-->
<!--          class="mr-50"-->
<!--          icon="HelpCircleIcon"-->
<!--          size="16"-->
<!--      />-->
<!--      <span>FAQ</span>-->
<!--    </b-dropdown-item>-->
    <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
    >
      <feather-icon
          class="mr-50"
          icon="LogOutIcon"
          size="16"
      />
      <span>Se déconnecter</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BAvatar, BDropdownDivider, BDropdownItem, BNavItemDropdown, } from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      this.$http
          .post('auth/logout',{'userPages': localStorage.getItem('userPages')})
          .then(response => {
            //window.Pusher.disconnect();
            localStorage.removeItem('accessToken')
            localStorage.removeItem('userData')
            localStorage.clear()
            this.$ability.update(initialAbility)
            this.$router.push({ name: 'auth-login' })
          })
          .catch((err) => {
            console.log(err)
          })
    },
  },
}
</script>
