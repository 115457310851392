<template>
  <p class="clearfix mb-0 text-center">
    <span class="float-md-left d-block d-md-inline-block mt-25" style="color:#fff;">
      © {{ currentYear }} BubbleIn
    </span>
      <b-link class="ml-50 mr-50" href="https://bubble-in.com/mentions-legales/" target="_blank" style="color:#fff;text-decoration: none;">Mentions légales
      </b-link>
      <b-link class="ml-50 mr-50" href="https://bubble-in.com/politique-de-confidentialite/" target="_blank" style="color:#fff;text-decoration: none;">Politique de confidentialité
      </b-link>
     <b-link class="ml-50 mr-50" href="/cgu.pdf" target="_blank" style="color:#fff;text-decoration: none;">Conditions générales d'utilisation
     </b-link>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
  data(){
    return {
      currentYear: new Date().getFullYear(), // 2020
    };
  },
 
}
</script>
