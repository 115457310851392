export default [{
    title: 'Statistiques',
    icon: 'BarChart2Icon',
    tagVariant: 'light-danger',
    typeUser: 'assureur',
    children: [{
            title: 'Production',
            route: '',
        },
        {
            title: 'Benchmark',
            route: '',
        },
        {
            title: 'Positionnement',
            route: '',
        }
    ]
}]