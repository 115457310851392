<template>
  <div
    class="app-content content"
    :class="[{'show-overlay': $store.state.app.shallShowOverlay}, $route.meta.contentClass]"
  >
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">

        <transition
          :name="routerTransition"
          mode="out-in"
        >
   
          <slot />
         
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    AppBreadcrumb

  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
}
</script>

<style>

@media (max-width: 1800px) {
  .content-body{
    margin-top:10px;
  }
}

@media (max-width: 1600px) {
  .content-body{
    margin-top:20px;
  }
}

@media (max-width: 1200px) {
  .content-body{
    margin-top:40px;
  }
}

@media (max-width: 1000px) {
  .content-body{
    margin-top:80px ;
  }
}

@media (max-width: 600px) {
  .content-body{
    margin-top:130px ;
  }
}
@media (max-width: 400px) {
  .content-body{
    margin-top:150px ;
  }
}

@media (max-width: 200px) {
  .content-body{
    margin-top:180px ;
  }
}



</style>
