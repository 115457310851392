/*

Array of object

Top level object can be:
1. Header
2. Group (Group can have navItems as children)
3. navItem

* Supported Options

/--- Header ---/

header

/--- nav Grp ---/

title
icon (if it's on top level)
tag
tagVariant
children

/--- nav Item ---/

icon (if it's on top level)
title
route: [route_obj/route_name] (I have to resolve name somehow from the route obj)
tag
tagVariant

*/
import messageries from './messageries'
import calendries from './calendries'
import portefeuilles from './portefeuilles'
import dashboard from './dashboard'
import marketplace from './marketplace'
import conformite from './conformite'
import animationCommerciale from './assureur/animation-commerciale'
import statistiques from './assureur/statistiques'
import organisation from './assureur/organisation'
import parametres from './assureur/parametres'
import portefeuille from './assureur/portefeuille'
import commissions from './commissions'
import cabinet from './cabinet'
import faqs from './faqs'
import outils from './outils'
import webinars from './webinars'
import backOffice from './back-office'
import monContactBubblin from './mon-contact-bubblin'
// import appsAndPages from './apps-and-pages'
// import others from './others'
// import chartsAndMaps from './charts-and-maps'
// import uiElements from './ui-elements'
// import formAndTable from './forms-and-table'

// Array of sections
// export default [...dashboard, ...appsAndPages, ...uiElements, ...formAndTable, ...chartsAndMaps, ...others]
// export default [
//   ...dashboard,
//   ...messageries,
//   ...calendries,
//   ...tiers,
//   ...projets,
//   ...portefeuilles,
//   // ...commissions,
//   ...conformite,
//   ...catalogues,
//   ...cabinet,
//   ...outils,
//   ...faqs,
//   ...backOffice,
// ]

// const userData = JSON.parse(localStorage.getItem('userData'))
//
// let menuList = []
// if (userData.type_utilisateur === 'assureur') {
//   menuList = [...dashboard, ...animationCommerciale]
//   console.log(menuList)
// } else if (userData.type_utilisateur === 'courtier') {
//   menuList = [...dashboard, ...messageries, ...calendries, ...tiers, ...projets, ...portefeuilles, ...conformite, ...catalogues, ...cabinet, ...outils, ...faqs]
//   console.log(menuList)
// } else if (userData.type_utilisateur === 'admin') {
//   menuList = [...dashboard, ...messageries, ...calendries, ...tiers, ...projets, ...portefeuilles, ...conformite, ...catalogues, ...cabinet, ...outils, ...faqs, ...backOffice, ...dashboard, ...animationCommerciale]
// }

export default [...dashboard, ...portefeuilles, ...marketplace, ...conformite, ...commissions, ...messageries, ...calendries, ...cabinet, ...outils, ...webinars, ...faqs,...monContactBubblin, ...backOffice, ...animationCommerciale, ...statistiques, ...portefeuille, ...organisation, ...parametres]