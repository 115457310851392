export default [{
    title: 'Back office',
    icon: 'ToolIcon',
    tagVariant: 'light-danger',
    typeUser: 'admin',
    children: [{
        title: 'Courtiers',
        route: 'back-office-courtiers',
    },
    {
        title: 'Assureurs',
        route: 'back-office-assureurs',
    },
    {

        title: 'Extractions',
        route: 'back-office-extraction',
    },
    {
        title: 'Statistiques',
        children: [{
            title: 'Sessions',
            route: 'back-office-statistiques-sessions',
        }
        ],
    },
    {
        title: 'Paramétrage',
        tagVariant: 'light-danger',
        children: [{
            title: 'Risque',
            route: 'back-office-risque',
        },
        {
            title: 'Guides',
            route: 'back-office-guide',
        },
        {
            title: 'Carrousel',
            route: 'back-office-carousels',
        },
        {
            title: 'Webinars',
            route: 'back-office-webinars',
        }
        ],
    },
    {
        title: 'Gestion',
        route: 'back-office-gestion',
        children: [
            {
                title: 'Facturation',
                route: 'back-office-gestion',
            },
            {
                title: 'Bordereau BBI',
                route: 'back-office-getstion-bordereauBBI',
            },
        ],
    },
    ],
}]
