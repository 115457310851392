export default [{
  title: 'Mon catalogue',
  icon: 'ShieldIcon',
  tagVariant: 'light-danger',
  typeUser: 'assureur',
  route: 'assureur-profile',

  // children: [{
  //   title: 'Nom Assureur',
  //   route: 'assureur-profile',
  // },
  // {
  //   title: 'Produits',
  //   route: 'produits-list',
  // }
  // ]
}
]
