export default [{
  title: 'Mes outils',
  icon: 'SettingsIcon',
  tagVariant: 'light-danger',
  typeUser: 'courtier',
  children: [
    // {
    //   title: 'Mon organisation',
    //   route: 'mon-organisation',
    // },
    // {
    //   title: 'Gestion cabinet',
    //   route: 'gestion-cabinet',
    // },
    // {
    //   title: 'Gestion collaborateurs',
    //   route: 'gestion-collaborateurs',
    // },
    {
      title: 'Modèles de mails',
      route: 'bibliotheque-mails',
    },
    {
      title: 'Annuaire des courtiers',
      route: 'annuaire',
    },
    {
      title: 'Modèles de procédures',
      route: 'modele-procedure',
    },
    {
      title: 'Guides',
      route: 'guide',
    },
  ],
}]
